<template>
  <div>
    <v-row :class="`${isMobile() ? 'mt-n12 mb-n12' : ''}`">
      <v-col
        cols="12"
        sm="12"
      >
        <div
          v-if="demoVideoId"
          class="text-center"
        >
          <vimeo-player
            :player-width="videoDimensions.width"
            :video-id="demoVideoId"
          />
        </div>
      </v-col>
    </v-row>
    <v-divider />
    <v-row>
      <v-col
        cols="12"
        sm="12"
      >
        <v-row>
          <template v-for="(item, n) in overviewImages">
            <v-col
              v-if="included(item)"
              :key="`overview-image-${n}`"
              cols="6"
              md="4"
            >
              <v-card
                flat
                tile
              >
                <v-img
                  :src="item.url"
                  :lazy-src="`https://picsum.photos/10/6?image=${n * 5 + 10}`"
                  aspect-ratio="1"
                />
              </v-card>
            </v-col>
          </template>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import dimensionUtils from '@/helpers/video_dimensions'

  export default {
    name: 'ImageOverview',
    props: {
      overviewImages: {
        type: Array[Object],
        required: true,
      },
      imagesToTakeByPointOfView: {
        type: Array,
        default: undefined,
      },
    },

    data () {
      return {
        videoOverlay: false,
        absolute: false,
        opacity: 1.0,
      }
    },

    computed: {
      demoVideoId () {
        return this.$t('HOW_TO_TAKE_IMAGES_VIMEO_VIDEO_LINK_ID')
      },
      videoDimensions () {
        return dimensionUtils.videoDimensions({ window: window })
      },
    },

    methods: {
      included (overviewImage) {
        let included = true
        if (this.imagesToTakeByPointOfView.includes('*')) { return included }
        if (this.imagesToTakeByPointOfView === undefined) { return included }
        const pointOfView = overviewImage.pointOfView
        included = this.imagesToTakeByPointOfView.includes(pointOfView)
        return included
      },
      isMobile () {
        return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
      },
    },
  }
</script>
